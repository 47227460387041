<template >
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">
            {{ $t("message.add_candidates") }}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'null'"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->
      <div>
        <div class="app-modal__body p-5 pb-0">
          <div class="timeline-items__right rounded-sm w-100 p-4" :class="mode ? 'table__myday' : 'table__mynight'">
            <el-row :gutter="20">
              <el-col :span="8">
                <p class="font-medium pb-1 pt-0 mb-3 mt-3" :class="mode ? 'text__day2' : 'text__night2'">
                  <strong class="mr-1 font-bold mr-2"
                    >{{ $t("message.nameGiven") }}:</strong
                  >
                  <span>{{ vacancy.name }}</span>
                </p>
              </el-col>
              <el-col :span="8" v-if="vacancy.genders">
                <p class="font-medium pb-1 pt-0 mb-3 mt-3">
                  <strong class="mr-1 font-bold mr-2"
                    >{{ $t("message.gender") }}:</strong
                  >
                  <span
                    v-for="(gender, index) in vacancy.genders"
                    :key="'gender-' + index"
                  >
                    {{ gender.name }}
                  </span>
                </p>
              </el-col>
              <el-col :span="8">
                <p class="font-medium pb-1 pt-0 mb-3 mt-3">
                  <strong class="mr-1 font-bold mr-2"
                    >{{ $t("message.age") }}:</strong
                  >
                  <span>
                    {{ vacancy.min_age + "-" + vacancy.max_age }}
                  </span>
                </p>
              </el-col>
              <el-col :span="8">
                <p class="font-medium pb-1 pt-0 mb-3 mt-3">
                  <strong class="mr-1 font-bold mr-2"
                    >{{ $t("message.department") }}:</strong
                  >
                  <span>
                    {{ vacancy.branch ? vacancy.branch.name : null }}
                  </span>
                </p>
              </el-col>
              <el-col :span="8" v-if="vacancy.departments">
                <p class="font-medium pb-1 pt-0 mb-3 mt-3">
                  <strong class="mr-1 font-bold mr-2"
                    >{{ $t("message.branch") }}:</strong
                  >
                  <span
                    v-for="(department, index) in vacancy.departments"
                    :key="'index' + index"
                  >
                    {{ department.name }}
                  </span>
                </p>
              </el-col>
              <el-col :span="8" v-if="vacancy.priority">
                <p class="font-medium pb-1 pt-0 mb-3 mt-3">
                  <strong class="mr-1 font-bold mr-2"
                    >{{ $t("message.priority") }}:</strong
                  >
                  <span>
                    {{ vacancy.priority ? vacancy.priority.name : null }}
                  </span>
                </p>
              </el-col>
              <el-col :span="8" v-if="vacancy.position">
                <p class="font-medium pb-1 pt-0 mb-3 mt-3">
                  <strong class="mr-1 font-bold mr-2"
                    >{{ $t("message.position") }}:</strong
                  >
                  <span>
                    {{ vacancy.position ? vacancy.position.name : null }}
                  </span>
                </p>
              </el-col>

              <el-col :span="8" v-if="vacancy.graphics">
                <p class="font-medium pb-1 pt-0 mb-3 mt-3">
                  <strong class="mr-1 font-bold mr-2"
                    >{{ $t("message.graphic") }}:</strong
                  >
                  <span
                    v-for="(graphic, index) in vacancy.graphics"
                    :key="'index' + index"
                  >
                    {{ graphic.name }}
                  </span>
                </p>
              </el-col>
              <el-col :span="8" v-if="vacancy.languages">
                <p class="font-medium pb-1 pt-0 mb-3 mt-3">
                  <strong class="mr-1 font-bold mr-2"
                    >{{ $t("message.language") }}:</strong
                  >
                  <span
                    v-for="(language, index) in vacancy.languages"
                    :key="'index' + index"
                  >
                    {{ language.name }}
                  </span>
                </p>
              </el-col>
              <el-col :span="8" v-if="vacancy.min_wage || vacancy.max_wage">
                <p class="font-medium pb-1 pt-0 mb-3 mt-3">
                  <strong class="mr-1 font-bold mr-2"
                    >{{ $t("message.salary") }}:</strong
                  >
                  <span>
                    {{ vacancy.min_wage + " - " + vacancy.max_wage }}
                  </span>
                </p>
              </el-col>
              <el-col :span="8" v-if="vacancy.skills">
                <p class="font-medium pb-1 pt-0 mb-3 mt-3">
                  <strong class="mr-1 font-bold mr-2"
                    >{{ $t("message.skill") }}:</strong
                  >
                  <span
                    v-for="(skill, index) in vacancy.skills"
                    :key="'index' + index"
                  >
                    {{ skill.skill }}
                  </span>
                </p>
              </el-col>
              <el-col :span="8" v-if="vacancy.region">
                <p class="font-medium pb-1 pt-0 mb-3 mt-3">
                  <strong class="mr-1 font-bold mr-2"
                    >{{ $t("message.region_") }}:</strong
                  >
                  <span>
                    {{ vacancy.region.name }}
                  </span>
                </p>
              </el-col>
              <el-col :span="8" v-if="vacancy.description">
                <p class="font-medium pb-1 pt-0 mb-3 mt-3">
                  <strong class="mr-1 font-bold mr-2"
                    >{{ $t("message.description") }}:</strong
                  >
                  <span>
                    {{ vacancy.description }}
                  </span>
                </p>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="app-modal__body p-5 pb-0" v-loading="loadingData">
          <div class="timeline-items__right rounded-sm w-100 p-4" :class="mode ? 'table__myday' : 'table__mynight'">
            <div class="mb-2 text-right">
              <el-button
                type="success"
                @click="drawerCreate = true"
                size="medium"
                plain
              >
                {{ $t("message.add_candidates") }}
              </el-button>
            </div>
            <table class="table-my-code table-bordered">
              <thead>
                <tr>
                  <th class="w50p" v-if="columns.id.show">
                    {{ columns.id.title }}
                  </th>

                  <th v-if="columns.name.show">
                    {{ columns.name.title }}
                  </th>

                  <th v-if="columns.last_name.show">
                    {{ columns.last_name.title }}
                  </th>
                  <th v-if="columns.date_of_birth.show">
                    {{ columns.date_of_birth.title }}
                  </th>

                  <th v-if="columns.gender_id.show">
                    {{ columns.gender_id.title }}
                  </th>

                  <th v-if="columns.status_id.show">
                    {{ columns.status_id.title }}
                  </th>
                  <th v-if="columns.phone_number.show">
                    {{ columns.phone_number.title }}
                  </th>

                  <th v-if="columns.min_wage.show">
                    {{ columns.min_wage.title }}
                  </th>

                  <th v-if="columns.max_wage.show">
                    {{ columns.max_wage.title }}
                  </th>
                  <th>
                    {{ $t("message.accept") }}
                  </th>

                  <th v-if="columns.settings.show">
                    {{ columns.settings.title }}
                  </th>
                </tr>

                <tr class="filter_sorche">
                  <th v-if="columns.id.show">
                    <crm-input
                      v-model="filterForm.id"
                      :placeholder="columns.id.title"
                      :type="'text'"
                      :className="'id_input'"
                      :class="mode ? 'input__day' : 'input__night'"
                    ></crm-input>
                  </th>
                  <th v-if="columns.name.show">
                    <crm-input
                    :class="mode ? 'input__day' : 'input__night'"
                      :placeholder="columns.name.title"
                      v-model="filterForm.name"
                    ></crm-input>
                  </th>
                  <th v-if="columns.last_name.show">
                    <crm-input
                    :class="mode ? 'input__day' : 'input__night'"
                      :placeholder="columns.last_name.title"
                      v-model="filterForm.last_name"
                    ></crm-input>
                  </th>
                  <th v-if="columns.date_of_birth.show">
                    <crm-date-picker
                      :placeholder="columns.date_of_birth.title"
                      v-model="filterForm.date_of_birth"
                    ></crm-date-picker>
                  </th>
                  <th v-if="columns.gender_id.show">
                    <select-gender
                      :placeholder="columns.gender_id.title"
                      v-model="filterForm.gender_id"
                    ></select-gender>
                  </th>
                  <th v-if="columns.status_id.show">
                    <select-status
                      :table_name="'candidates'"
                      :placeholder="columns.status_id.title"
                      v-model="filterForm.status_id"
                    ></select-status>
                  </th>
                  <th v-if="columns.phone_number.show">
                    <crm-input
                    :class="mode ? 'input__day' : 'input__night'"
                      :type="'tel'"
                      :placeholder="columns.phone_number.title"
                      v-model="filterForm.phone_number"
                    ></crm-input>
                  </th>
                  <th v-if="columns.min_wage.show">
                    <crm-input
                    :class="mode ? 'input__day' : 'input__night'"
                      :placeholder="columns.min_wage.title"
                      v-model="filterForm.min_wage"
                    ></crm-input>
                  </th>
                  <th v-if="columns.max_wage.show">
                    <crm-input
                    :class="mode ? 'input__day' : 'input__night'"
                      :placeholder="columns.max_wage.title"
                      v-model="filterForm.max_wage"
                    ></crm-input>
                  </th>
                  <th v-if="columns.max_wage.show">
                    
                  </th>
                  <th class="settinW" v-if="columns.settings.show"></th>
                </tr>
              </thead>

              <transition-group name="flip-list" tag="tbody">
                <tr
                  v-for="(candidate, index) in list"
                  :key="index + 'candidate.id'"
                  class="cursor-pointer"
                >
                  <td v-if="columns.id.show">
                    {{ candidate.id }}
                  </td>

                  <td v-if="columns.name.show">
                    {{ candidate.name }}
                  </td>

                  <td v-if="columns.last_name.show">
                    {{ candidate.last_name }}
                  </td>

                  <td v-if="columns.date_of_birth.show">
                    {{ candidate.date_of_birth }}
                  </td>

                  <td v-if="columns.gender_id.show">
                    {{ candidate.gender ? candidate.gender.name : null }}
                  </td>
                  <td v-if="columns.status_id.show">
                    {{ candidate.status ? candidate.status.name : null }}
                  </td>
                  <td v-if="columns.phone_number.show">
                    {{ candidate.phone_number }}
                  </td>
                  <td v-if="columns.min_wage.show">
                    {{ candidate.min_wage }}
                  </td>
                  <td v-if="columns.max_wage.show">
                    {{ candidate.max_wage }}
                  </td>
                  <td v-if="columns.max_wage.show">
                    <el-button
                      size="mini"
                      type="primary"
                      @click="addStaff(candidate.id)"
                      class="copy-link"
                    >
                      {{ $t("message.accept") }}
                    </el-button>
                  </td>
                  <td v-if="columns.settings.show" class="settings-td">
                    <crm-settings
                      :name="$options.name"
                      :model="candidate"
                      :actions="actions"
                      @edit="edit"
                      @delete="destroy"
                    ></crm-settings>
                  </td>
                </tr>
              </transition-group>
            </table>
            <div class="my___pagination">
              <crm-pagination
                @c-change="updatePagination"
                :pagination="pagination"
                :class="mode ? 'pagination__day' : 'pagination__night'"
              ></crm-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="app-modal app-modal__full">
      <el-drawer
        :with-header="false"
        :visible.sync="drawerCreate"
        :append-to-body="true"
        size="70%"
        ref="drawerCreate"
        @opened="drawerOpened('drawerCreateChild')"
        @closed="drawerClosed('drawerCreateChild')"
      >
        <crm-create-candidate
          :vacancy="vacancy"
          ref="drawerCreateChild"
          drawer="drawerCreate"
        ></crm-create-candidate>
      </el-drawer>
      <el-drawer
        :with-header="false"
        :visible.sync="drawerUpdate"
        :append-to-body="true"
        size="70%"
        ref="drawerUpdate"
        @opened="drawerOpened('drawerUpdateChild')"
        @closed="drawerClosed('drawerUpdateChild')"
      >
        <crm-update-candidate
          :selectedItem="selectedItem"
          ref="drawerUpdateChild"
          drawer="drawerUpdate"
        ></crm-update-candidate>
      </el-drawer>
      <el-drawer
        :with-header="false"
        :wrapperClosable="false"
        :visible.sync="drawerCreateStaff"
        size="95%"
        :append-to-body="true"
        ref="drawerCreateStaff"
        @opened="drawerOpened('drawerCreateStaffChild')"
        @closed="drawerClosed('drawerCreateStaffChild')"
        >
        <crm-staff-create-new
          :candidate_id="selectedCandidate"
          ref="drawerCreateStaffChild"
          drawer="drawerCreateStaff"
        ></crm-staff-create-new>
      </el-drawer>
    </div>
  </div>
</template>
<script>
import CrmCreateCandidate from "../../candidates/components/crm-create.vue";
import crmUpdateCandidate from "../../candidates/components/crm-update.vue";
import selectGender from "@/components/filters/inventory/select-gender";
import selectStatus from "@/components/filters/inventory/select-status";
import CrmStaffCreateNew from "../../candidates/components/crm-staff-create-new";
import drawer from "@/utils/mixins/drawer";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  mixins: [drawer],
  name: "VacancyController",
  components: {
    CrmCreateCandidate,
    selectGender,
    selectStatus,
    crmUpdateCandidate,
    CrmStaffCreateNew
  },
  props: {
    vacancy: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      drawerCreate: false,
      drawerShow: false,
      drawerUpdate: false,
      filterForm: {},
      loadingData: false,
      excel_data: [],
      excel_fields: {},
      checkAll: false,
      selectedItems: [],
      selectedItem: null,
      reloadList: false,
      loadingComments: false,
      drawerCreateStaff: false,
      selectedCandidate: null,
    };
  },
  watch: {
    filterForm: {
      handler: async function (newVal, oldVal) {
        await this.updatePagination({ key: "page", value: 1 });
        await this.updateFilter(newVal);
        this.debouncedFetchData();
      },
      deep: true,
      immediate: true,
    },
    sort: {
      handler: async function (newVal, oldVal) {
        if (newVal != oldVal && _.isFunction(this.debouncedFetchData)) {
          this.debouncedFetchData();
        }
      },
      deep: true,
      immediate: true,
    },
    "pagination.page": {
      handler: async function (newVal, oldVal) {
        if (newVal != oldVal && _.isFunction(this.debouncedFetchData)) {
          this.debouncedFetchData();
        }
      },
      deep: true,
      immediate: true,
    },
    "pagination.per_page": {
      handler: async function (newVal, oldVal) {
        if (newVal != oldVal && _.isFunction(this.debouncedFetchData)) {
          this.debouncedFetchData();
        }
      },
      deep: true,
      immediate: true,
    },
    columns: {
      handler: function () {
        this.controlExcelData();
      },
      deep: true,
    },
  },
  created() {
    this.filterForm = JSON.parse(JSON.stringify(this.filter));
    this.debouncedFetchData = _.debounce(this.fetchData, 500);
  },
  mounted() {
    if (_.isFunction(this.controlExcelData)) {
      this.controlExcelData();
    }
  },
  computed: {
    ...mapGetters({
      list: "vacancy/vacancy_candidate_list",
      columns: "candidate/columns",
      pagination: "vacancy/candidate_pagination",
      statues: "vacancy/statues",
      filter: "vacancy/filter",
      sort: "vacancy/sort",
      mode:'MODE'
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  methods: {
    ...mapActions({
      updateList: "vacancy/candidate",
      updateSort: "vacancy/updateSort",
      updateFilter: "vacancy/updateFilter",
      updateColumn: "vacancy/updateColumn",
      updatePagination: "vacancy/updateCandidatePagination",
      show: "vacancy/show",
      empty: "vacancy/empty",
      delete: "candidate/destroy",
      refreshData: "vacancy/refreshData",
    }),
    afterOpen() {
      this.fetchData();
    },
    crmCandidate(model) {
      this.drawerCandidate = true;
    },
    closeDrawer(drawer) {
      if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
        this.$refs[drawer].closeDrawer();
      }
    },
    drawerClosed(ref) {
      if (this.$refs[ref]) {
        this.$refs[ref].closed();
      }
      if (this.reloadList === true) {
        this.fetchData();
        this.afterFetchData();
      }
      if (_.isFunction(this.empty)) {
        this.empty();
      }
    },
    drawerOpened(ref) {
      if (this.$refs[ref]) {
        if (_.isFunction(this.$refs[ref].opened)) {
          this.$refs[ref].opened();
        }
      }
    },
    listChanged() {
      this.reloadList = true;
    },
    afterFetchData() {
      this.reloadList = false;
    },
    fetchData() {
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      if (!this.loadingData && this.vacancy.id) {
        this.loadingData = true;
        this.updateList({ vacancy_id: this.vacancy.id, params: query })
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => {});
    },
    edit(model) {
      this.selectedItem = model;
      this.drawerUpdate = true;
    },
    destroy(model) {
      this.delete(model.id)
        .then((res) => {
          this.$alert(res);
          this.fetchData();
        })
        .catch((err) => {
          alert(err);
        });
    },
    addStaff(id) {
      console.log(id);
      this.selectedCandidate = id;
      this.drawerCreateStaff = true;
    },
  },
};
</script>
